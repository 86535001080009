<template>
  <v-layout v-if="_.size(detail) > 0" row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 md12>
      <v-card>
        <titleCard title="Seo" subtitle="Titulos y descripciones..."></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap text-xs-center>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="detail.name"
                label="Nombre del producto"
                color="secondary"
                counter="55"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="detail.nameGeneric"
                label="Nombre del generico"
                color="secondary"
                counter="55"
                hint="(sin especificar cantidad)"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field v-model="detail.sku" label="Codigo" color="secondary" disabled></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="detail.video"
                label="Video youtube"
                color="secondary"
                hint="Ejemplo: https://www.youtube.com/watch?v=3wILQcYlqzQ"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <QuillEditorFormat
                v-model="detail.description"
                :reference="'descriptionOrder'"
                placeholder="Descripcion del producto"
              >
              </QuillEditorFormat>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                v-model="detail.descriptionShort"
                label="Descripcion corta del producto"
                auto-grow
                color="secondary"
                rows="3"
                counter="160"
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field v-model="detail.seo.url" label="Url amigable" color="secondary"></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-textarea v-model="detail.seo.keys" label="Palabras claves" color="secondary" rows="2"></v-textarea>
            </v-flex>
            <v-flex xs12 sm12>
              <v-textarea
                v-model="detail.seo.description"
                label="Descripcion Seo (Meta description)"
                auto-grow
                color="secondary"
                rows="3"
                counter="160"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat :loading="loadingBtn" @click="save"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { GET_PRODUCT } from '../../config'
import titleCard from '../useful/titleCard.vue'
import QuillEditorFormat from '../useful/quillEditorFormat.vue'

export default {
  name: 'ProductSeo',
  components: { titleCard, QuillEditorFormat },
  props: ['product', 'getProduct'],
  data: () => ({
    loadingBtn: false,
    loading: false,
    active: true,
    stock: true,
    scheduleRetirement: 0,
    detail: {},
    states: [
      { name: 'Florida', abbr: 'FL', id: 1 },
      { name: 'Georgia', abbr: 'GA', id: 2 },
      { name: 'Nebraska', abbr: 'NE', id: 3 },
      { name: 'California', abbr: 'CA', id: 4 },
      { name: 'New York', abbr: 'NY', id: 5 }
    ]
  }),
  watch: {
    product() {
      this.initComponent()
    }
  },
  mounted() {
    this.initComponent()
  },
  methods: {
    async save() {
      let keys = this.detail.seo.keys
      if (typeof this.detail.seo.keys === 'string') {
        keys = keys.split(',')
      }
      this.detail.seo.keys = keys
      this.loadingBtn = true
      try {
        this.$http.put(`${GET_PRODUCT}/${this.product.id}/seo`, {
          id: this.detail.id,
          name: this.detail.name,
          nameGeneric: this.detail.nameGeneric,
          video: this.detail.video,
          description: this.detail.description,
          descriptionShort: this.detail.descriptionShort,
          seo: this.detail.seo
        })
        setTimeout(() => {
          this.getProduct()
          this.loadingBtn = false
        }, 500)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
        this.loadingBtn = false
      }
    },
    initComponent() {
      this.detail = this.product
      this.detail.name = this.$options.filters.toHTML(this.detail.name)
      this.detail.nameGeneric = this.$options.filters.toHTML(this.detail.nameGeneric)
      this.detail.descriptionShort = this.$options.filters.toHTML(this.detail.descriptionShort)
      this.detail.seo.keys.forEach((element, index) => {
        this.detail.seo.keys[index] = this.$options.filters.toHTML(element)
      })
      this.detail.seo.description = this.$options.filters.toHTML(this.detail.seo.description)
    },
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.abbr.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
    }
  }
}
</script>

<style></style>
